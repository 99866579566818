<template>
  <div>
    <b-sidebar
      id="sidebar-task-handler"
      sidebar-class="sidebar-lg"
      :visible="isTaskHandlerSidebarActive"
      bg-variant="white"
      shadow
      backdrop
      no-header
      right
      @change="(val) => $emit('update:is-task-handler-sidebar-active', val)"
      @hidden="clearForm"
    >
      <template #default="{ hide }">
        <!-- Header -->
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <b-button
            v-if="taskLocal.id"
            size="sm"
            :variant="taskLocal.isCompleted ? 'outline-success' : 'outline-secondary'"
            @click="taskLocal.isCompleted = !taskLocal.isCompleted"
          >
            {{ taskLocal.isCompleted ? 'Completed' : 'Mark Complete' }}
          </b-button>
          <h5
            v-else
            class="mb-0"
          >
            Ажил нэмэх
          </h5>
          <div>
            <feather-icon
              v-show="taskLocal.id"
              icon="TrashIcon"
              class="cursor-pointer"
              @click="$emit('remove-task'); hide();"
            />
            <feather-icon
              class="ml-1 cursor-pointer"
              icon="StarIcon"
              size="16"
              :class="{ 'text-warning': taskLocal.isImportant }"
              @click="taskLocal.isImportant = !taskLocal.isImportant"
            />
            <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              size="16"
              @click="hide"
            />
          </div>
        </div>

        <!-- Body -->
        <validation-observer
          #default="{ handleSubmit }"
          ref="refFormObserver"
        >

          <!-- Form -->
          <b-form
            class="p-2"
            @submit.prevent="handleSubmit(onSubmit)"
            @reset.prevent="resetForm"
          >
            <b-form-group
                label=""
                label-for="task-call-type"
              >
              <b-form-radio-group
                v-model="taskLocal.call_type"
                aria-controls="ex-disabled-readonly"
              >
                <b-form-radio value="inbound">
                  Гаднаас залгасан
                </b-form-radio>
                <b-form-radio value="outbound">
                  Гадагшаа залгасан
                </b-form-radio>
              </b-form-radio-group>
            </b-form-group>
            <!-- Title -->
            <validation-provider
              #default="validationContext"
              name="Утас"
              rules="required"
            >
              <b-form-group
                label="Утас"
                label-for="phone"
              >
                <b-form-input
                  id="phone"
                  v-model="taskLocal.phone"
                  autofocus
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder="Утас"
                  @blur="getCustomer(taskLocal.phone)"
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <validation-provider
              #default="validationContext"
              name="Овог"
            >
              <b-form-group
                label="Овог"
                label-for="lastname"
              >
                <b-form-input
                  id="lastname"
                  v-model="taskLocal.customer.lastname"
                  autofocus
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder="Овог"
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <validation-provider
              #default="validationContext"
              name="Нэр"
            >
              <b-form-group
                label="Нэр"
                label-for="firstname"
              >
                <b-form-input
                  id="firstname"
                  v-model="taskLocal.customer.firstname"
                  autofocus
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder="Нэр"
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <validation-provider
              #default="validationContext"
              name="Регистрийн дугаар"
            >
              <b-form-group
                label="Регистрийн дугаар"
                label-for="register_number"
              >
                <b-form-input
                  id="register_number"
                  v-model="taskLocal.customer.register_number"
                  autofocus
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder="Регистрийн дугаар"
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <!-- <validation-provider
              #default="validationContext"
              name="Гарчиг"
              rules="required"
            >
              <b-form-group
                label="Гарчиг"
                label-for="task-title"
              >
                <b-form-input
                  id="task-title"
                  v-model="taskLocal.title"
                  autofocus
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder="Гарчиг"
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider> -->

            <!--Category -->
            <b-form-group
              label="Ангилал"
              label-for="categories"
            >
              <treeselect 
                v-model="taskLocal.categoryIds" 
                :multiple="true" 
                :options="refCategoryOptions" 
                :disable-branch-nodes="true" search-nested
                label="name" 
                placeholder="Ангилал" 
                :closeOnSelect="true"
              />
            </b-form-group>

            <!--Products -->
            <b-form-group
              label="Бүтээгдэхүүн"
              label-for="tag"
            >
              <treeselect 
                v-model="taskLocal.productIds" 
                :multiple="true" 
                :options="refProductOptions" 
                :disable-branch-nodes="true" search-nested
                label="name" 
                placeholder="Бүтээгдэхүүн" 
                :closeOnSelect="true"
              />
            </b-form-group>

            <!--Tag -->
            <b-form-group
              label="Таг"
              label-for="tag"
            >
              <v-select
                v-model="taskLocal.tagIds"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                multiple
                :options="refTagOptions"
                :reduce="option => option.id"
                label="name"
                input-id="tags"
                placeholder="Таг"
              />
            </b-form-group>

            <!--Priority -->
            <b-form-group
              label="Ач холбогдол"
              label-for="priority"
            >
              <v-select
                v-model="taskLocal.priority"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="refPriorityOptions"
                :reduce="option => option.name"
                label="name"
                input-id="priority"
                placeholder="Ач холбогдол"
              />
            </b-form-group>

            <!-- Description -->
            <b-form-group
              label="Асуудал"
              label-for="quil-content"
            >
              <b-form-textarea 
               v-model="taskLocal.description" 
               rows="3" 
               placeholder="Асуудал" />
            </b-form-group>

            <!--Team -->
            <b-form-group
              label="Баг"
              label-for="team"
            >
              <v-select
                v-model="taskLocal.team"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="teamOptions"
                :reduce="option => option.name"
                label="name"
                input-id="team"
                @input="changeTeam($event)"
                placeholder="Баг"
              />
            </b-form-group>

            <!-- Assignee -->
            <b-form-group
              label="Хариуцагч"
              label-for="assignee"
            >
              <v-select
                v-model="taskLocal.assignee"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :reduce="option => option.username"
                :options="userOptions"
                label="username"
                class="assignee-selector"
                input-id="assignee"
                placeholder="Хариуцагч"
              >

                <!-- <template #option="{ avatar, fullName }">
                  <b-avatar
                    size="26"
                    :src="avatar"
                  />
                  <span class="ml-50 d-inline-block align-middle"> {{ fullName }}</span>
                </template>

                <template #selected-option="{ avatar, fullName }">
                  <b-avatar
                    size="26"
                    :src="avatar"
                    :variant="`light-${resolveAvatarVariant(taskLocal.tags)}`"
                    :text="avatarText(fullName)"
                  />

                  <span class="ml-50 d-inline-block align-middle"> {{ fullName }}</span>
                </template> -->
              </v-select>
            </b-form-group>

            <!-- due Date -->
            <validation-provider
              #default="validationContext"
              name="Дуусах хугацаа"
              rules="required"
            >

              <b-form-group
                label="Дуусах хугацаа"
                label-for="due-date"
              >
                <flat-pickr
                  id="due-date"
                  v-model="taskLocal.due_date"
                  class="form-control"
                  :config="{ enableTime: true,dateFormat: 'Y-m-d H:i'}"
                />
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <validation-provider #default="{ errors }" name="Issue title" rules="required">
              <b-form-group label="Төлөх эрмэлзлэл" label-for="pay-score">
                <b-form-spinbutton
                  id="pay-score"
                  v-model="taskLocal.pay_score"
                  min="1"
                  max="100"
                  :disabled="taskLocal.call_type === 'inbound'"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>

            <b-form-group label="Эргэн холбогдох">
              <!-- <h5>Эргэн холбогдох</h5> -->
              <flat-pickr
                v-model="taskLocal.promised_at"
                class="form-control"
                :config="{ enableTime: true,dateFormat: 'Y-m-d H:i'}"
                :disabled="taskLocal.call_type === 'inbound'"
              />
            </b-form-group>

            <!-- Form Actions -->
            <div class="d-flex mt-2">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-2"
                type="submit"
              >
                {{ taskLocal.id ? 'Өөрчлөх' : 'Нэмэх ' }}
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="reset"
                variant="outline-secondary"
              >
                Буцаах
              </b-button>
            </div>
          </b-form>
        </validation-observer>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BAvatar, BButton, BFormInvalidFeedback, BFormRadioGroup, BFormRadio, BFormSpinbutton, BFormTextarea
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
extend('required', {
  ...required,
  message: 'Заавал бөглөх талбар бөглөөгүй байна'
});

// Add the email rule
extend('email', {
  ...email,
  message: 'Заавал и-мэйл бүтэцтэй байна'
});
import { required, email, url } from '@validations'
import { avatarText } from '@core/utils/filter'
import formValidation from '@core/comp-functions/forms/form-validation'
import { toRefs } from '@vue/composition-api'
import { quillEditor } from 'vue-quill-editor'
import useTaskHandler from './useTaskHandler'
import Treeselect from '@riophae/vue-treeselect'
  // import the styles
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import store from '@/store'

export default {
  components: {
    // BSV
    BButton,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BAvatar,
    BFormInvalidFeedback,
    BFormRadioGroup,
    BFormRadio,
    BFormSpinbutton,
    BFormTextarea,
    // 3rd party packages
    vSelect,
    flatPickr,
    quillEditor,
    Treeselect ,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isTaskHandlerSidebarActive',
    event: 'update:is-task-handler-sidebar-active',
  },
  props: {
    isTaskHandlerSidebarActive: {
      type: Boolean,
      required: true,
    },
    task: {
      type: Object,
      required: true,
    },
    clearTaskData: {
      type: Function,
      required: true,
    },
  },
  created () {
    store.dispatch('app-todo/fetchRefTags', {  })
    .then(response => {
      this.refTagOptions = response.data.response
    })

    store.dispatch('app-todo/fetchRefCategoriesWithChildren', {  })
    .then(response => {
      this.refCategoryOptions = response.data.response
    })

    store.dispatch('app-todo/fetchRefProductsWithChildren', {  })
    .then(response => {
      this.refProductOptions = response.data.response
    })

    store.dispatch('app-todo/fetchTeamsByFilter', { organization_name: 'Нөмөр Кредит ББСБ' })
    .then(response => {
      this.teamOptions = response.data.response
    })

    store.dispatch('app-todo/fetchRefTags', {  })
    .then(response => {
      this.refTagOptions = response.data.response
    })

    store.dispatch('app-todo/fetchRefPriorities', {  })
    .then(response => {
      this.refPriorityOptions = response.data.response
    })
  },
  data() {
    return {
      userOptions: [],
      teamOptions: [],
      refTagOptions: [],
      refCategoryOptions: [],
      refProductOptions: [],
      refPriorityOptions: [],
      required,
      email,
      url,
      value: null,
    }
  },
  setup(props, { emit }) {
    const {
      taskLocal,
      resetTaskLocal,

      // UI
      assigneeOptions,
      onSubmit,
      tagOptions,
      resolveAvatarVariant,
    } = useTaskHandler(toRefs(props), emit)

    const {
      refFormObserver,
      getValidationState,
      resetForm,
      clearForm,
    } = formValidation(resetTaskLocal, props.clearTaskData)

    const editorOption = {
      modules: {
        toolbar: '#quill-toolbar-sidebar',
      },
      placeholder: 'Дэлгэрэнгүй бичих',
    }

    return {
      // Add New
      taskLocal,
      onSubmit,
      assigneeOptions,
      tagOptions,

      // Form Validation
      resetForm,
      clearForm,
      refFormObserver,
      getValidationState,

      // UI
      editorOption,
      resolveAvatarVariant,

      // Filter/Formatter
      avatarText,
    }
  },
  methods: {
    changeTeam (param) {
      store.dispatch('app-todo/fetchUsersByFilter', { team_name: param })
      .then(response => {
        this.userOptions = response.data.response
      })
    },
    // selectCategories (param) {
    //   this.taskLocal.categories = this.refCategoryOptions.filter(x => param.includes(x.id) )

    // },
    // selectProducts (param) {
    //   this.taskLocal.products = this.refProductOptions.filter(x => param.includes(x.id) )
    // },
    getCustomer (param) {
      store.dispatch('app-todo/getCustomer', { phone: param, ticket_id: this.taskLocal.id })
      .then(response => {
        if (response.data.response) {
          this.taskLocal.customer.phone = param
          this.taskLocal.customer = response.data.response
          this.taskLocal.customer.customer_id = response.data.response.id
          this.taskLocal.customer.customer_per_id = response.data.response['per.id']
        }
      })
    },
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/quill.scss';
</style>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';

.assignee-selector {
  ::v-deep .vs__dropdown-toggle {
  padding-left: 0;
  }
}

#quil-content ::v-deep {
  > .ql-container {
    border-bottom: 0;
  }

  + #quill-toolbar-sidebar {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
  }
}
</style>
