<template>
  
  <!-- Need to add height inherit because Vue 2 don't support multiple root ele -->
  <section class="app-todo-wrapper">
    <todo :is-task-handler-sidebar-active.sync="isTaskHandlerSidebarActive" @re-fetch-task="reFetchTask" @filter-task-by-phone="listFilterByPhone" @filter-task-by-created-by="listFilterByCreatedBy" :boardName="boardName" :phoneList="phoneList" :createdByList="createdByList"></todo>
    <div class="todo-wrapper ps">
      <!-- <div class="body-content-overlay" :class="{ 'show': mqShallShowLeftSidebar }" @click="mqShallShowLeftSidebar = false" /> -->
    <div class="todo-app-list">

      <!-- App Searchbar Header -->
      <div class="app-fixed-search d-flex align-items-center">

        <!-- Toggler -->
        <!-- <div class="sidebar-toggle d-block d-lg-none ml-1">
          <feather-icon icon="MenuIcon" size="21" class="cursor-pointer" @click="mqShallShowLeftSidebar = true" />
        </div> -->

        <!-- Searchbar -->
        <div class="d-flex align-content-center justify-content-between w-100">
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text>
              <feather-icon icon="SearchIcon" class="text-muted" />
            </b-input-group-prepend>
            <b-form-input :value="searchQuery" placeholder="Ажил хайх" @input="updateRouteQuery" />
          </b-input-group>
        </div>

        <!-- Dropdown -->
        <div class="dropdown">
          <b-dropdown variant="link" no-caret toggle-class="p-0 mr-1" right>
            <template #button-content>
              <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
            </template>
            <b-dropdown-item @click="resetSortAndNavigate">
              Reset Sort
            </b-dropdown-item>
            <b-dropdown-item :to="{ name: $route.name, query: { ...$route.query, sort: 'title-asc' } }">
              Sort A-Z
            </b-dropdown-item>
            <b-dropdown-item :to="{ name: $route.name, query: { ...$route.query, sort: 'title-desc' } }">
              Sort Z-A
            </b-dropdown-item>
            <b-dropdown-item :to="{ name: $route.name, query: { ...$route.query, sort: 'assignee' } }">
              Sort Assignee
            </b-dropdown-item>
            <b-dropdown-item :to="{ name: $route.name, query: { ...$route.query, sort: 'due-date' } }">
              Sort Due Date
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </div>

      <!-- Todo List -->
      <vue-perfect-scrollbar :settings="perfectScrollbarSettings" class="todo-task-list-wrapper list-group scroll-area">
        <draggable v-model="tasks" handle=".draggable-task-handle" tag="ul" class="todo-task-list media-list">
          <li v-for="task in tasks" :key="task.id" class="todo-item" :class="{ 'completed': task.isCompleted }"
            v-ripple.400="'rgba(113, 102, 240, 0.15)'" v-b-modal.modal-task-detail @click="handleTaskClick(task)">
            <feather-icon icon="MoreVerticalIcon" class="draggable-task-handle d-inline" />
            <div class="todo-title-wrapper">
              <div class="todo-title-area">
                <div class="title-wrapper">
                  <b-form-checkbox :checked="task.isCompleted" @click.native.stop @change="updateTaskIsCompleted(task)" />
                  <span class="todo-title">{{ task.description }}</span>
                </div>
              </div>
              <div class="todo-item-action">
                <div class="badge-wrapper mr-1">
                  <b-badge v-for="tag in task.tags" :key="tag" pill :variant="`light-${resolveTagVariant(tag)}`"
                    class="text-capitalize">
                    {{ tag }}
                  </b-badge>
                </div>
                <!-- <small class="text-nowrap text-muted mr-1">{{ formatDate(task.due_date, { month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric' })
                }}</small> -->
                <b-avatar
                  v-if="task.assignee"
                  size="32"
                  :src="task.assignee"
                  :text="avatarText(task.assignee)"
                />
                <b-avatar
                  v-else
                  size="32"
                  variant="light-secondary"
                >
                  <feather-icon icon="UserIcon" size="16" />
                </b-avatar>
              </div>
            </div>

          </li>
        </draggable>
        <div class="no-results" :class="{ 'show': !tasks.length }">
          <h5>Ажил олдсонгүй</h5>
        </div>
      </vue-perfect-scrollbar>
      </div>

      <!-- Task Handler -->
      <todo-task-handler-sidebar v-model="isTaskHandlerSidebarActive" :task="task" :clear-task-data="clearTaskData"
      @remove-task="removeTask" @add-task="addTask" @update-task="updateTask" />

      <todo-task-edit v-model="isTaskHandlerModalActive" :task="task" :lists="lists" :clear-task-data="clearTaskData" @update-task="updateTask" @re-fetch-task="reFetchTask"/>

      <!-- Sidebar -->
      <!-- <portal to="content-renderer-sidebar-left" style="{margin-top: 50px;}">
      <todo-left-sidebar :task-tags="taskTags" :is-task-handler-sidebar-active.sync="isTaskHandlerSidebarActive"
        :class="{ 'show': mqShallShowLeftSidebar }" @close-left-sidebar="mqShallShowLeftSidebar = false" />
      </portal> -->
    </div>
  </section>
</template>

<script>
import store from '@/store'
import {
  ref, watch, computed, onUnmounted,
} from '@vue/composition-api'
import {
  BFormInput, BInputGroup, BInputGroupPrepend, BDropdown, BDropdownItem,
  BFormCheckbox, BBadge, BAvatar, BButton, VBModal
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import draggable from 'vuedraggable'
import { formatDate, avatarText } from '@core/utils/filter'
import { useRouter } from '@core/utils/utils'
import { useResponsiveAppLeftSidebarVisibility } from '@core/comp-functions/ui/app'
import TodoLeftSidebar from './TodoLeftSidebar.vue'
import todoStoreModule from './todoStoreModule'
import TodoTaskHandlerSidebar from './TodoTaskHandlerSidebar.vue'
import TodoTaskEdit from './TodoTaskEdit.vue'
import Ripple from 'vue-ripple-directive'
import Todo from './Todo.vue'
import { listeners } from 'process'


export default {
  components: {
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BDropdown,
    BDropdownItem,
    BFormCheckbox,
    BBadge,
    BAvatar,
    BButton,
    draggable,
    VuePerfectScrollbar,

    // App SFC
    TodoLeftSidebar,
    TodoTaskHandlerSidebar,
    TodoTaskEdit,
    Todo
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  setup() {
    const TODO_APP_STORE_MODULE_NAME = 'app-todo'

    // Register module
    if (!store.hasModule(TODO_APP_STORE_MODULE_NAME)) store.registerModule(TODO_APP_STORE_MODULE_NAME, todoStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(TODO_APP_STORE_MODULE_NAME)) store.unregisterModule(TODO_APP_STORE_MODULE_NAME)
    })

    const { route, router } = useRouter()
    const routeSortBy = computed(() => route.value.query.sort)
    const routeQuery = computed(() => route.value.query.q)
    const routeParams = computed(() => route.value.params)
    watch(routeParams, () => {
      // eslint-disable-next-line no-use-before-define
      fetchTasks()
    })

    const tasks = ref([])

    const lists = ref([])

    const allLists = ref([])

    const isTaskHandlerSidebarActive = ref(false)

    const isTaskHandlerModalActive = ref(false)

    const phoneList = ref([])

    const createdByList = ref([])
    
    const requestParam = ref(null)

    const boardName = ref('')

    const sortOptions = [
      'latest',
      'title-asc',
      'title-desc',
      'assignee',
      'due-date',
    ]
    const sortBy = ref(routeSortBy.value)
    watch(routeSortBy, val => {
      if (sortOptions.includes(val)) sortBy.value = val
      else sortBy.value = val
    })
    const resetSortAndNavigate = () => {
      const currentRouteQuery = JSON.parse(JSON.stringify(route.value.query))

      delete currentRouteQuery.sort

      router.replace({ name: route.name, query: currentRouteQuery }).catch(() => { })
    }

    const blankTask = {
      id: null,
      title: '',
      // uurt ni bsan
      dueDate: new Date(),
      description: '',
      assignee: null,
      isCompleted: false,
      isDeleted: false,
      isImportant: false,
      // nemsen
      code: '',
      phone: '',
      status: 'Шинэ',
      categoryIds: [],
      productIds: [],
      tagIds: [],
      attachmentNames: [],
      priority: '',
      workspace: 'Нөмөр Кредит ББСБ',
      space: 'Нөмөр асуудал',
      folder: 'Нээлттэй хавтас',
      board: 'Нээлттэй самбар',
      list: 'Шинэ',
      due_date: new Date(),
      is_complete: false,
      is_draft: false,
      is_sent: false,
      created_at: new Date(),
      created_by: '',
      customer_id: '',
      merchant_id: '',
      firstname: '',
      lastname: '',
      organization: 'Нөмөр Кредит ББСБ',
      team: '',
      is_deleted: false,
      // deleted_at: null,
      // deleted_by: '',
      call_type: 'inbound',
      pay_score: 0,
      promised_at: null,
      started_at: null,
      finished_at: null,
      returned_at: null,
      completed_at: null,
      comments: [
      ],
      comment: '',
      customer: {
        customer_id: 0,
        customer_per_id: 0,
        firstname: '',
        lastname: '',
        register_number: '',
        ticket_id: 0,
        phoen: ''
      }
    }
    const task = ref(JSON.parse(JSON.stringify(blankTask)))
    const clearTaskData = () => {
      task.value = JSON.parse(JSON.stringify(blankTask))
    }

    const addTask = val => {
      console.log('val', val)
      store.dispatch('app-todo/addTask', val)
        .then(() => {
          // eslint-disable-next-line no-use-before-define
          fetchTasksByBoard()
        })
    }
    const removeTask = () => {
      store.dispatch('app-todo/removeTask', { id: task.value.id })
        .then(() => {
          // eslint-disable-next-line no-use-before-define
          fetchTasksByBoard()
        })
    }
    const updateTask = taskData => {
      store.dispatch('app-todo/updateTask', taskData)
        .then(() => {
          // eslint-disable-next-line no-use-before-define
          fetchTasksByBoard()
        })
    }

    const reFetchTask = (param) => {
      console.log('testqwqwqwqw', param)
      fetchTasksByBoard(param)
    }

    const perfectScrollbarSettings = {
      maxScrollbarLength: 150,
    }


    const taskTags = [
      { title: 'Team', color: 'primary', route: { name: 'todo-tag', params: { tag: 'team' } } },
      { title: 'Low', color: 'success', route: { name: 'todo-tag', params: { tag: 'low' } } },
      { title: 'Medium', color: 'warning', route: { name: 'todo-tag', params: { tag: 'medium' } } },
      { title: 'High', color: 'danger', route: { name: 'todo-tag', params: { tag: 'high' } } },
      { title: 'Update', color: 'info', route: { name: 'todo-tag', params: { tag: 'update' } } },
    ]

    const resolveTagVariant = tag => {
      if (tag === 'team') return 'primary'
      if (tag === 'low') return 'success'
      if (tag === 'medium') return 'warning'
      if (tag === 'high') return 'danger'
      if (tag === 'update') return 'info'
      return 'primary'
    }

    const resolveAvatarVariant = tags => {
      if (tags.includes('high')) return 'primary'
      if (tags.includes('medium')) return 'warning'
      if (tags.includes('low')) return 'success'
      if (tags.includes('update')) return 'danger'
      if (tags.includes('team')) return 'info'
      return 'primary'
    }

    // Search Query
    const searchQuery = ref(routeQuery.value)
    watch(routeQuery, val => {
      searchQuery.value = val
    })
    // eslint-disable-next-line no-use-before-define
    watch([searchQuery, sortBy], () => fetchTasks())
    const updateRouteQuery = val => {
      const currentRouteQuery = JSON.parse(JSON.stringify(route.value.query))

      if (val) currentRouteQuery.q = val
      else delete currentRouteQuery.q

      router.replace({ name: route.name, query: currentRouteQuery })
    }

    // const fetchTasks = () => {
    //   store.dispatch('app-todo/fetchTasks', {
    //     q: searchQuery.value,
    //     filter: router.currentRoute.params.filter,
    //     tag: router.currentRoute.params.tag,
    //     sortBy: sortBy.value,
    //   })
    //     .then(response => {
    //       tasks.value = response.data.response
    //     })
    // }

    // fetchTasks()
    const fetchTasksByBoard = (param) => {
      createdByList.value = []
          phoneList.value = []
      if (route.value.name === "todo-list") {
        requestParam.value = {
          board:'Нээлттэй самбар'
        }
        boardName.value = ''
      } else {
        let date = new Date();
        date.setDate(date.getDate() - 1);
        boardName.value = (date.toISOString().split('T')[0])
        if (!param) {
          requestParam.value = {
            board: boardName.value
          }
        } else {
          requestParam.value = {
            board: param
          }
        }
        console.log(requestParam)
        
      }
      store.dispatch('app-todo/fetchTasksByBoard', requestParam.value)
        .then(response => {
          lists.value = response.data.response
          for (let pIndex in response.data.response) {
            for (let cIndex in response.data.response[pIndex].tickets) {
              if (!createdByList.value.find(x => x.name === response.data.response[pIndex].tickets[cIndex].created_by )) {
                createdByList.value.push(
                  {
                    name: response.data.response[pIndex].tickets[cIndex].created_by
                  }
                )
              }
              if (!phoneList.value.find(pl => pl.name === response.data.response[pIndex].tickets[cIndex].phone )) {
                phoneList.value.push(
                  {
                    name: response.data.response[pIndex].tickets[cIndex].phone
                  }
                )
              }
              tasks.value.push(Object.assign({}, response.data.response[pIndex].tickets[cIndex]))
              allLists.value.push(Object.assign({}, response.data.response[pIndex].tickets[cIndex]))
            }
          }
        })
    }

    fetchTasksByBoard(null)

    const handleTaskClick = taskData => {
      store.dispatch('app-todo/fetchTask', taskData)
        .then(response => {
          task.value = response.data.response
          isTaskHandlerModalActive.value = true
        })
    }

    const addTaskClick = taskData => {
      task.value = taskData
      isTaskHandlerSidebarActive.value = true
    }

    // Single Task isCompleted update
    const updateTaskIsCompleted = taskData => {
      // eslint-disable-next-line no-param-reassign
      taskData.isCompleted = !taskData.isCompleted
      updateTask(taskData)
    }

    const { mqShallShowLeftSidebar } = useResponsiveAppLeftSidebarVisibility()

    const listFilterByPhone = taskData => {
      if (taskData) {
        tasks.value = allLists.value.filter(t => t.phone === taskData)
      } else {
        tasks.value = allLists.value
      }
    }

    const listFilterByCreatedBy = taskData => {
      if (taskData) {
        tasks.value = allLists.value.filter(t => t.created_by === taskData)
      } else {
        tasks.value = allLists.value
      }
    }

    return {
      task,
      tasks,
      lists,
      allLists,
      removeTask,
      addTask,
      updateTask,
      reFetchTask,
      clearTaskData,
      taskTags,
      searchQuery,
      fetchTasksByBoard,
      perfectScrollbarSettings,
      updateRouteQuery,
      resetSortAndNavigate,

      // UI
      resolveTagVariant,
      resolveAvatarVariant,
      isTaskHandlerSidebarActive,
      isTaskHandlerModalActive,
      // Click addTask
      addTaskClick,
      // Click Handler
      handleTaskClick,
      listFilterByPhone,
      listFilterByCreatedBy,

      // Filters
      formatDate,
      avatarText,

      // Single Task isCompleted update
      updateTaskIsCompleted,

      // Left Sidebar Responsive
      mqShallShowLeftSidebar,
      requestParam,
      boardName,
      phoneList,
      createdByList
    }
  },
}
</script>

<style lang="scss" scoped>
.draggable-task-handle {
  position: absolute;
  left: 8px;
  top: 50%;
  transform: translateY(-50%);
  visibility: hidden;
  cursor: move;

  .todo-task-list .todo-item:hover & {
    visibility: visible;
  }
}
</style>

<style lang="scss">
@import "~@core/scss/base/pages/app-todo.scss";
</style>
