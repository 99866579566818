<template>
  <div>
    <b-modal id="modal-task-detail" cancel-variant="outline-secondary" no-close-on-backdrop size="xl" hide-footer
      hide-header centered title="Ажлын дэлгэрэнгүй" ref="task-edit-modal"
      @hidden="clearForm">
      <template #default="{ hide }">
        <!-- Header -->
        <div class="d-flex justify-content-between align-items-center content-modal-header px-2 py-1">
          <b-dropdown v-if="taskLocal.id" size="sm"
            text=""
            variant="outline-primary"
            right
            split
          >
            <template #button-content>
              <feather-icon icon="TrelloIcon" /> {{ taskLocal.status }}
            </template>
            <b-dropdown-item v-for="(list, listIndex ) in lists" :key="listIndex" :disabled="taskLocal.status === list.name" @click="changeStatus(taskLocal, list)">{{ list.name }}</b-dropdown-item>
            <!-- <b-dropdown-divider />
            <b-dropdown-item>Separated link</b-dropdown-item> -->
          </b-dropdown>
          <h5 v-else class="mb-0">
            Ажлын дэлгэрэнгүй
          </h5>
          <template v-for="list in lists" >
            <b-button title="Дууссан" v-if="list.name === 'Дууссан' && taskLocal.list !== 'Дууссан'" :key="list.name" v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="outline-primary" @click="changeStatus(taskLocal, list)" 
            class="mr-auto ms-25 done-button d-flex align-items-center justify-content-center" style="padding: 0.5rem 1rem">
            <feather-icon icon="CheckIcon" />
           </b-button>
          </template>
          <div>
            <feather-icon v-show="taskLocal.id" icon="TrashIcon" class="cursor-pointer"
              @click="$emit('remove-task'); hide();" />
            <feather-icon class="ml-1 cursor-pointer" icon="StarIcon" size="16"
              :class="{ 'text-warning': taskLocal.isImportant }"
              @click="taskLocal.isImportant = !taskLocal.isImportant" />
            <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
          </div>
        </div>
        <validation-observer #default="{ handleSubmit }" ref="refFormTodoEditObserver">

          <!-- Form -->
          <b-form class="p-2" @submit.prevent="handleSubmit(onSubmit)" @reset.prevent="resetForm" ref="task-edit-form">
            <b-form-group label="" label-for="task-call-type">
              <b-form-radio-group v-model="taskLocal.call_type" aria-controls="ex-disabled-readonly">
                <b-form-radio value="inbound">
                  Гаднаас залгасан
                </b-form-radio>
                <b-form-radio value="outbound">
                  Гадагшаа залгасан
                </b-form-radio>
              </b-form-radio-group>
            </b-form-group>
            <b-row>
              <b-col md="6">
                <validation-provider #default="validationContext" name="Утас" rules="required">
                  <b-form-group label="Утас" label-for="phone">
                    <b-input-group>
                      <b-form-input id="phone" v-model="taskLocal.phone" autofocus
                      :state="getValidationState(validationContext)" trim placeholder="Утас"
                      @blur="getCustomer(taskLocal)" />
                      <b-input-group-append>
                        <b-button variant="outline-primary" @click="gotoAdmin(taskLocal)">
                          Админруу үсрэх
                        </b-button>
                      </b-input-group-append>
                    </b-input-group>
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
                <validation-provider #default="validationContext" name="Овог" rules="required">
                  <b-form-group label="Овог" label-for="lastname">
                    <b-form-input id="lastname" v-model="taskLocal.customer.lastname" autofocus
                      :state="getValidationState(validationContext)" trim placeholder="Овог" />

                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
                <validation-provider #default="validationContext" name="Нэр" rules="required">
                  <b-form-group label="Нэр" label-for="firstname">
                    <b-form-input id="firstname" v-model="taskLocal.customer.firstname" autofocus
                      :state="getValidationState(validationContext)" trim placeholder="Нэр" />

                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
                <validation-provider #default="validationContext" name="Регистрийн дугаар" rules="required">
                  <b-form-group label="Регистрийн дугаар" label-for="register_number">
                    <b-form-input id="register_number" v-model="taskLocal.customer.register_number" autofocus
                      :state="getValidationState(validationContext)" trim placeholder="Регистрийн дугаар" />

                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
                <!-- Title -->
                <!-- <validation-provider #default="validationContext" name="Гарчиг" rules="required">
                  <b-form-group label="Гарчиг" label-for="task-title">
                    <b-form-input id="task-title" v-model="taskLocal.title" autofocus
                      :state="getValidationState(validationContext)" trim placeholder="Гарчиг" />
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider> -->

                <!-- Description -->
                <b-form-group label="Асуудал" label-for="quil-content">
                  <b-form-textarea v-model="taskLocal.description" rows="3" placeholder="Асуудал" />
                </b-form-group>

                <b-form-group label="Файл хавсаргах">
                    <el-upload
                      class="upload-demo"
                      ref="uploadImage"
                      action="#"
                      :show-file-list="false"
                      :auto-upload="false"
                      :on-change="saveImage"
                    >
                    <b-list-group>
                      <b-list-group-item v-for="(attachment, index) of taskLocal.attachmentNames" :key="index" class="d-flex justify-content-between align-items-center">
                        <span>{{ attachment }}</span>
                        <div class="__list_group_buttons">
                          <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" variant="outline-primary" size="sm" @click="downloadAttachment(attachment)" class="btn-icon" style="margin-right: 10px;">
                            <feather-icon icon="DownloadIcon" />
                          </b-button>
                          <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" variant="outline-primary" size="sm" @click="removeAttachment(attachment)" class="btn-icon">
                            <feather-icon icon="DeleteIcon" />
                          </b-button>
                        </div>
                      </b-list-group-item>
                    </b-list-group>
                    <el-button slot="trigger" size="small" type="primary">select file</el-button>
                    </el-upload>
                  </b-form-group>

                <b-tabs>
                  <b-tab active title="Сэтгэгдэл">
                    <div v-for="(comment, ind) in taskLocal.comments" :key="ind" class="d-flex align-items-start mb-1">
                      <b-avatar v-if="comment.created_by" size="34" :src="comment.created_by"
                        :text="avatarText(comment.created_by)" class="mt-25 mr-75" />
                      <b-avatar v-else size="34" variant="light-secondary" class="mt-25 mr-75">
                        <feather-icon icon="UserIcon" size="16" />
                      </b-avatar>
                      <!-- <b-avatar :src="comment.avatar" size="34" class="mt-25 mr-75" /> -->
                      <div class="profile-user-info w-100">
                        <div class="d-flex align-items-center justify-content-between">
                          <h6 class="mb-0">
                            {{ comment.created_by }}
                          </h6>
                          <!-- <b-link class="text-body">
                            <feather-icon icon="HeartIcon" size="18" class="mr-25"
                              :class="comment.youLiked ? 'profile-likes' : 'profile-icon'" />
                            <span class="text-muted align-middle">{{ comment.commentsLikes }}</span>
                          </b-link> -->
                        </div>
                        <small>{{ comment.comment }}</small>
                      </div>
                    </div>
                    <!--/ comments -->

                    <!-- comment box -->
                    <b-form-group>
                      <b-form-textarea v-model="taskLocal.comment" rows="3" placeholder="Сэтгэгдэл үлдээх" />
                    </b-form-group>
                    <!--/ comment box -->

                    <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" size="sm" variant="primary" @click="addComment(taskLocal)">
                      Сэтгэгдэл үлдээх
                    </b-button>
                  </b-tab>
                  <b-tab title="Түүх">
                    <!-- <b-card-text>
                      Biscuit macaroon sugar plum sesame snaps oat cake halvah fruitcake pudding cotton candy. Cheesecake
                      tart wafer soufflé. Chocolate marzipan donut pie soufflé dragée cheesecake. Gummi bears dessert
                      croissant chocolate jujubes fruitcake. Pie cupcake halvah.
                    </b-card-text>
                    <b-card-text>Tiramisu carrot cake marzipan sugar plum powder marzipan sugar plum bonbon powder.
                      Macaroon jujubes ice cream sugar plum lollipop wafer caramels. Cheesecake chocolate tart cake
                      gingerbread fruitcake cake candy jelly-o. Candy cookie lollipop. Wafer lemon drops chocolate cake
                      gummi bears.</b-card-text> -->
                  </b-tab>
                </b-tabs>
              </b-col>
              <b-col md="6">
                <!--Category -->
                <b-form-group label="Ангилал" label-for="categories">
                  <treeselect v-model="taskLocal.categoryIds" :multiple="true" :options="refCategoryOptions" :disable-branch-nodes="true" search-nested label="name"
                    placeholder="Ангилал" />
                </b-form-group>

                <!--Products -->
                <b-form-group label="Бүтээгдэхүүн" label-for="tag">
                  <treeselect v-model="taskLocal.productIds" :multiple="true" :options="refProductOptions" :disable-branch-nodes="true" search-nested label="name"
                    placeholder="Бүтээгдэхүүн" />
                </b-form-group>

                <!--Tag -->
                <b-form-group label="Таг" label-for="tag">
                  <v-select v-model="taskLocal.tagIds" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" multiple
                    :options="refTagOptions" :reduce="option => option.id" label="name"
                    input-id="tags" placeholder="Таг" />
                </b-form-group>

                <!--Priority -->
                <b-form-group label="Ач холбогдол" label-for="priority">
                  <v-select v-model="taskLocal.priority" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="refPriorityOptions" :reduce="option => option.name" label="name"
                    input-id="priority" placeholder="Ач холбогдол" />
                </b-form-group>

                <!--Team -->
                <b-form-group label="Баг" label-for="team">
                  <v-select v-model="taskLocal.team" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="teamOptions" :reduce="option => option.name" label="name"
                    input-id="team" @input="changeTeam($event)" placeholder="Баг" />
                </b-form-group>

                <!-- Assignee -->
                <b-form-group label="Хариуцагч" label-for="assignee">
                  <v-select v-model="taskLocal.assignee" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="userOptions" :reduce="option => option.username" label="username" class="assignee-selector" input-id="assignee"
                    placeholder="Хариуцагч">

                    <!-- <template #option="{ avatar, fullName }">
                        <b-avatar
                          size="26"
                          :src="avatar"
                        />
                        <span class="ml-50 d-inline-block align-middle"> {{ fullName }}</span>
                      </template>

                      <template #selected-option="{ avatar, fullName }">
                        <b-avatar
                          size="26"
                          :src="avatar"
                          :variant="`light-${resolveAvatarVariant(taskLocal.tags)}`"
                          :text="avatarText(fullName)"
                        />

                        <span class="ml-50 d-inline-block align-middle"> {{ fullName }}</span>
                      </template> -->
                  </v-select>
                </b-form-group>

                <!-- due Date -->
                <validation-provider #default="validationContext" name="Дуусах хугацаа" rules="required">

                  <b-form-group label="Дуусах хугацаа" label-for="due-date">
                    <flat-pickr id="due-date" v-model="taskLocal.due_date" class="form-control" :config="{ enableTime: true, dateFormat: 'Y-m-d H:i' }"/>
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>

                <validation-provider #default="{ errors }" name="Issue title" rules="required">
                  <b-form-group label="Төлөх эрмэлзлэл" label-for="pay-score">
                    <b-form-spinbutton id="pay-score" v-model="taskLocal.pay_score" min="1" max="100"
                      :disabled="taskLocal.call_type === 'inbound'" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>

                <b-form-group>
                  <!-- <h5>Эргэн холбогдох</h5> -->
                  Эргэн холбогдох
                  <flat-pickr v-model="taskLocal.promised_at" class="form-control"
                    :config="{ enableTime: true, dateFormat: 'Y-m-d H:i' }"
                    :disabled="taskLocal.call_type === 'inbound'" />
                </b-form-group>
                <validation-provider #default="validationContext" name="Бүртгэсэн" rules="required">
                  <b-form-group label="Бүртгэсэн" label-for="created_by">
                    <b-form-input id="created_by" v-model="taskLocal.created_by" disabled
                      :state="getValidationState(validationContext)" trim placeholder="Нэр" />

                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>

                <!-- Form Actions -->
                <div class="d-flex mt-2">
                  <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mr-2" type="submit">
                    {{ taskLocal.id ? 'Өөрчлөх' : 'Нэмэх ' }}
                  </b-button>
                  <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" type="reset" variant="outline-secondary">
                    Буцаах
                  </b-button>
                </div>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </template>
    </b-modal>
  </div>
</template>

<script>
import {
  BCol, BRow, BSidebar, BForm, BFormGroup, BFormInput, BInputGroup, BInputGroupAppend, BInputGroupPrepend, BFormTextarea, BAvatar, BButton, BButtonGroup, BFormInvalidFeedback, BFormRadioGroup, BFormRadio, BFormSpinbutton, BTabs, BTab, BFormFile,BDropdown,
  BDropdownItem,
  BDropdownDivider, BListGroup, BListGroupItem
  
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
extend('required', {
  ...required,
  message: 'Заавал бөглөх талбар бөглөөгүй байна'
});

// Add the email rule
extend('email', {
  ...email,
  message: 'Заавал и-мэйл бүтэцтэй байна'
});
import { required, email, url } from '@validations'
import { avatarText } from '@core/utils/filter'
import formValidation from '@core/comp-functions/forms/form-validation'
import { toRefs } from '@vue/composition-api'
import { quillEditor } from 'vue-quill-editor'
import useTaskHandler from './useTaskHandler'
import Treeselect from '@riophae/vue-treeselect'
// import the styles
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import store from '@/store'
import { ref } from '@vue/composition-api'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import getBase64 from '@/utils/getBase64.js'
export default {
  components: {
    // BSV
    BRow,
    BCol,
    BButton,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BInputGroupPrepend,
    BFormFile,
    BListGroup, 
    BListGroupItem,
    BAvatar,
    BFormInvalidFeedback,
    BFormRadioGroup,
    BFormRadio,
    BFormSpinbutton,
    BTabs,
    BTab,
    BFormTextarea,
    BButtonGroup,
    BDropdown,
    BDropdownItem,
    BDropdownDivider,
      // 3rd party packages
    vSelect,
    flatPickr,
    quillEditor,
    Treeselect,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isTaskHandlerModalActive',
    event: 'update:is-task-handler-modal-active',
  },
  props: {
    isTaskHandlerModalActive: {
      type: Boolean,
      required: true,
    },
    task: {
      type: Object,
      required: true,
    },
    clearTaskData: {
      type: Function,
      required: true,
    },
    lists: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      required,
      email,
      url,
      value: null
    }
  },
  setup (props, { emit }) {
    const toast = useToast()
    const refTagOptions = ref([])
    const refCategoryOptions = ref([])
    const refProductOptions = ref([])
    const teamOptions = ref([])
    const userOptions = ref([])
    const refPriorityOptions = ref([])

    store.dispatch('app-todo/fetchRefTags', {})
      .then(response => {
        refTagOptions.value = response.data.response
      })

    store.dispatch('app-todo/fetchRefCategoriesWithChildren', {})
      .then(response => {
        refCategoryOptions.value = response.data.response.map(x => { return { ...x, label: x.name } })
      })

    store.dispatch('app-todo/fetchRefProductsWithChildren', {})
      .then(response => {
        refProductOptions.value = response.data.response.map(x => { return { ...x, label: x.name } })
      })

    store.dispatch('app-todo/fetchTeamsByFilter', { organization_name: 'Нөмөр Кредит ББСБ' })
      .then(response => {
        teamOptions.value = response.data.response
      })

    store.dispatch('app-todo/fetchRefPriorities', {})
      .then(response => {
        refPriorityOptions.value = response.data.response
      })
    const {
      taskLocal,
      resetTaskLocal,

      // UI
      assigneeOptions,
      onSubmit,
      resolveAvatarVariant,
    } = useTaskHandler(toRefs(props), emit)

    const {
      refFormObserver,
      getValidationState,
      resetForm,
      clearForm,
    } = formValidation(resetTaskLocal, props.clearTaskData)

    const editorOption = {
      modules: {
        toolbar: '#quill-toolbar-modal',
      },
      placeholder: 'Дэлгэрэнгүй бичих',
    }

    const changeStatus = (taskLocal, newList) => {
      store.dispatch('app-todo/changeStatusTask', { ticketId: taskLocal.id, newList: newList })
          .then(response => {
            taskLocal.status = newList.ref_status
            taskLocal.list = newList.name
            toast({
              component: ToastificationContent,
              props: {
                title: response.data.message,
                icon: 'BellIcon',
                variant: 'success',
              },
            },
            {
              position: 'bottom-right'
            })
            emit('re-fetch-task')
          }).catch(error => {
            toast({
              component: ToastificationContent,
              props: {
                title: 'Алдаа гарлаа',
                icon: 'BellIcon',
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right'
            })
          })


    }

    const changeTeam =  (param) => {
      store.dispatch('app-todo/fetchUsersByFilter', { team_name: param })
        .then(response => {
          userOptions.value = response.data.response
          console.log("testttt")
          console.log(userOptions.value.username)
        }).catch(error => {
            toast({
              component: ToastificationContent,
              props: {
                title: 'Алдаа гарлаа',
                icon: 'BellIcon',
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right'
            })
          })
    }

    const addComment =  (taskLocal) => {
      console.log(taskLocal)
      if (taskLocal.comment === "" || taskLocal.comment === null || taskLocal.comment === undefined) {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Сэтгэгдэл бичээгүй байна',
            icon: 'BellIcon',
            variant: 'warning',
          },
        },
        {
          position: 'bottom-right'
        })
        return
      }
      store.dispatch('app-todo/addComment', { comment: taskLocal.comment, ticketId: taskLocal.id })
        .then(response => {
          taskLocal.comments = response.data.response
          taskLocal.comment = ""
        }).catch(error => {
            toast({
              component: ToastificationContent,
              props: {
                title: 'Алдаа гарлаа',
                icon: 'BellIcon',
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right'
            })
          })
    }

    const getCustomer = (taskLocal,) => {
      store.dispatch('app-todo/getCustomer', { phone: taskLocal.phone, ticket_id: taskLocal.id })
        .then(response => {
          if (response.data.response) {
            taskLocal.customer.phone = param
            taskLocal.customer = response.data.response
            taskLocal.customer.customer_id = response.data.response.id
            taskLocal.customer.customer_per_id = response.data.response['per.id']
          }
        }).catch(error => {
            toast({
              component: ToastificationContent,
              props: {
                title: 'Алдаа гарлаа',
                icon: 'BellIcon',
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right'
            })
          })
    }

    const gotoAdmin = (taskLocal) => {
      console.log("asasasas", taskLocal)
      let param = "https://admin.numur.mn/#/admin/customers/" + taskLocal.customer.customer_id + "/detail"
      window.open(param, '_blank');
    }

    const downloadAttachment = (fileName) => {
      let param = "https://s3.eu-west-1.amazonaws.com/ticket.numur.mn/" + fileName
      console.log(param)
      window.open(param, '_blank');
    }

    const removeAttachment = (fileName) => {
      let fileIndex = taskLocal.value.attachmentNames.indexOf(fileName)
        taskLocal.value.attachmentNames.splice(fileIndex, 1)
    }

    const saveImage = (file) => {
      console.log(file)
      if (taskLocal.value.customer.register_number === "") {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Уучлаарай харилцагчийн мэдээлэл бөглөөгүй байна",
            icon: 'BellIcon',
            variant: 'danger',
          },
        })
        console.log(taskLocal)
        return
      }
      getBase64(file.raw).then(image => {
        const base64 = image.split('base64,')
        let payload = {
          file: base64[1], 
          file_name: file.name,
          register_number: taskLocal.value.customer.register_number
        }
        store.dispatch('app-todo/fileUpload', payload).then((response) => {
          console.log(response.data.response)
          taskLocal.value.attachmentNames.push(response.data.response)
        }).catch(function (error) {
          console.log("errr". error)
        })
      })
    }


    return {
      // Add New
      taskLocal,
      onSubmit,
      assigneeOptions,
      changeStatus,
      changeTeam,
      addComment,
      getCustomer,
      gotoAdmin,
      downloadAttachment,
      removeAttachment,
      saveImage,
      //
      userOptions,
      teamOptions,
      refTagOptions,
      refCategoryOptions,
      refProductOptions,
      refPriorityOptions,
      // Form Validation
      resetForm,
      clearForm,
      refFormObserver,
      getValidationState,

      // UI
      editorOption,
      resolveAvatarVariant,

      // Filter/Formatter
      avatarText,
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/quill.scss';
</style>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';

.assignee-selector {
  ::v-deep .vs__dropdown-toggle {
    padding-left: 0;
  }
}

#quil-content ::v-deep {
  >.ql-container {
    border-bottom: 0;
  }

  +#quill-toolbar-modal {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
  }
}

.done-button:hover,
  .done-button:hover .popover {
    background-color: #13795b !important;
    color: white !important;
}

</style>
